import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import bannerimg from "../../images/bg-img.jpg"
import "./assets/styles/_index.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const ThankBanner = (props) => {
  const imagename = "event.cta_section_image.cta_image"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms) {
    processedImages = props?.imagetransforms?.cta_section_image_Transforms
  }

  return (
    <section className="thank-banner-wrapper section-p">
      {/* <ImageModule
        ImageSrc={props?.image}
        altText={`${
          props?.image?.alternativeText
            ? props?.image?.alternativeText
            : props?.title
            ? props?.title
            : props?.title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={props?.id}
        classNames="img-fluid cta-img"
      /> */}
      <div
        style={{ backgroundImage: `url(${bannerimg})` }}
        className="cta-img"
      />
      <Container>
        <Row className="d-flex justify-content-center">
          <Col xl={8}>
            <div className="text-center">
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={100}
                animateOnce
                offset={10}
              >
                <h2>
                  Thank you for registering for our upcoming {props.title}{" "}
                  Webinar.
                </h2>
              </ScrollAnimation>
              {props.content && (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={200}
                  animateOnce
                  offset={10}
                >
                  <p>{props?.content}</p>
                </ScrollAnimation>
              )}
              <ul className="list-inline pattern-list">
                <li className="list-inline-item">
                  <Link
                    // href="#"
                    to={props?.currentUrl}
                    className="button button-primary-color"
                  >
                    Back to {props?.type} page
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ThankBanner
