import * as React from "react"

import Address from "../components/Address/Address"
import ThankBanner from "../components/ThankyouBanner/ThankyouBanner"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThankYouPage = () => {
  const type = typeof window !== "undefined" ? window.history.state.type : ""
  const currentUrl =
    typeof window !== "undefined" ? window.history.state.currentUrl : ""
  const title = typeof window !== "undefined" ? window.history.state.title : ""
  const content =
    typeof window !== "undefined" ? window.history.state.content : ""
  const thankingAddress =
    typeof window !== "undefined" ? window.history.state.thankingAddress : ""

  return (
    <Layout dark>
      <div className="layout-padding-top" />
      <ThankBanner
        content={content}
        type={type}
        title={title}
        currentUrl={currentUrl}
      />
      <Address content={thankingAddress} />
    </Layout>
  )
}

export const Head = () => <Seo title="Thank you" />

export default ThankYouPage
